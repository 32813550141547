.text-support {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    }
.text-support.text-center {
    color: #E5E5E5;
    width: 100%;
    justify-content: center;
}
.text-support.text-center h6 {
    font-size: 14px;
    color: #E5E5E5;
}
.text-support.text-center a {
    color: #E5E5E5;
}
