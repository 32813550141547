.focusedform .verticalcenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 42px !important;
    gap: 24px;
    border-radius: 16px;
    background: #FFFFFF;
    width: 420px;
    margin-bottom: 0%;
    top: 30%;
}
.focusedform .brand {
    padding-bottom: 0px;
}
[class*="panel-"].panel .panel-body {
    display: flex;
    flex-direction: column;
    padding: 0px;
    padding-bottom: 20px;
    display: contents;
}
.uptrade__login-panel {
    margin: 0%;
}
.lang-box-container-login {
    margin: 0%;
}

.center-text .text-main {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #000000;
    margin-bottom: 0%;
    padding-bottom: 20px;
}

form {
    display: flex;
    flex-direction: column;
}

.form-group label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #646464;
    margin-bottom: 0px;
    padding-bottom: 6px;
}

.controls .form-control {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 4px;
    background: white;
    border: 1px solid #C8C8C8 !important;
    border-radius: 8px !important;
    color: #7D7D7D;
}
.controls .help-block {
    font-family: "Roboto";
    font-style: normal;
    font-size: 12px;
    line-height: 150%;
    padding-top: 5px;
    margin-left: 10px;
    color: #EB5757;
}
.has-error .control-label {
    color: #EB5757;
}
.controls input[name="username"] {
    color: #191919;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
}
.controls input[name="password"] {
    color: #191919;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
}
.checkbox input[type="checkbox"] {
    accent-color: #2022ED;
    border: 1.66667px solid #FFFFFF;
}
.checkbox{
    padding-left: 25px;
}
.form-group-checkbox-label-left .checkbox {
    margin-bottom: 0;
    margin-top: 0;
    margin-left: -14px;
    margin-right: 15px;
    justify-self: left;
}
.form-group-checkbox-label-left .checkbox label {
    color: #000000;
    font-size: 13px;
    padding-top: 8px;
    padding-bottom: 16px;
}

.form-group-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 0px;
    font-family: "Roboto";
    font-style: normal;
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    color: #000000;
}
.form-group-btn-login {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 12px;
    width: 100%;
    padding-bottom: 5px;
}
.form-group-btn-login-pass {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 12px;
    width: 100%;
}
.form-group-btn-login-pass .uptrade__login-panel-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 10px;
    background: #F6F8FF;
    border-radius: 44px;
    width: 100%;
    color: #2022ED;
}
.form-group-btn-login-pass .uptrade__login-panel-btn:hover {
    background-color: #F6F8FF;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #2022ED;
}
.form-group-btn-login-pass .uptrade__login-panel-btn:active {
    background-color: #F6F8FF;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #2022ED;
}
.form-group-btn-login-pass .uptrade__login-panel-btn:focus {
    background-color: #F6F8FF;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #2022ED;
}
.form-group-btn-login-pass .uptrade__login-panel-btn-pass {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #FFFFFF;
    color: #646464;
    font-size: 12px;
    width: 100%;
    /* margin-left: -42px; */
}
.form-group-btn-login-pass .uptrade__login-panel-btn-pass:hover {
    background-color: #ffffff;
    color: #646464;
}
.form-group-btn-login-pass .uptrade__login-panel-btn-pass:focus {
    background-color: #ffffff;
    color: #646464;
}
.form-group-btn-login-pass .uptrade__login-panel-btn-pass:active {
    background-color: #ffffff;
    color: #646464;
}
.form-group-btn-login .uptrade__login-panel-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 10px;
    background: #F6F8FF;
    border-radius: 44px;
    width: 100%;
    color: #2022ED;
}
.form-group-btn-login .uptrade__login-panel-btn:hover {
    background-color: #F6F8FF;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #2022ED;
}
.form-group-btn-login .uptrade__login-panel-btn:active {
    background-color: #F6F8FF;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #2022ED;
}
.form-group-btn-login .uptrade__login-panel-btn:focus {
    background-color: #F6F8FF;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #2022ED;
}
.form-group-btn-pass {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 12px;
    width: 100%;
}
.form-group-btn-pass .uptrade__login-panel-btn-pass {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #FFFFFF;
    color: #646464;
    font-size: 12px;
}
.form-group-btn-pass .uptrade__login-panel-btn-pass:hover {
    background-color: #FFFFFF;
    color: #646464;
}
.form-group-btn-pass .uptrade__login-panel-btn-pass:active {
    background-color: #FFFFFF;
    color: #646464;
}
.form-group-btn-pass .uptrade__login-panel-btn-pass:focus {
    background-color: #FFFFFF;
    color: #646464;
}

div#div_id_password.form-group{
    margin-bottom: 18px;
}
div#div_id_username.form-group{
    margin-bottom: 18px;
}

strong {
    font-weight:normal;
}
.asteriskField {
    display: none;
}
.alert.alert-danger-login {
    background-color: #FFF7F9;
    color: #EB5757;
    border-color: #FFF7F9;
    background-image: url("img/logos/alert-triangle.svg");
    font-size: 13px;
    line-height: 150%;
}
