.focusedform .verticalcenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 42px;
    padding-bottom: 10px;
    gap: 24px;
    border-radius: 16px;
    background: #FFFFFF;
    max-width: 420px;
    margin-bottom: 0%;
    top: 30%;
}
.focusedform .panel {
    width: 100%
}
.panel {
    margin: 0%;
}

.text-center {
    display: flex;
    flex-direction: column;
    padding: 0px;
}
.text-center .text-main-no-padding-top {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #000000;
    padding-top: 0px;
    margin-top: 0px;
}
.text-center .text-main-margin-bottom {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #000000;
    padding-top: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
}
.text-center .text-main {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #000000;
    padding-top: 16px;
    margin-top: 0px;
}
.text-center .text-help {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
    color: #646464;
    margin-bottom: 8px;
}
.text-center .text-help-top-6-px {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
    color: #646464;
    margin-bottom: 10px;
    margin-top: 6px;
}
.text-center .text-help-padding-bottom {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
    color: #646464;
    margin-top: 6px !important;
    padding-bottom: 6px !important;
}
.text-center .text-help-bottom-16px {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
    color: #646464;
    margin-bottom: 16px;
    margin-top: 6px;
}
.text-center .text-help-no-padding-bottom {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
    color: #646464;
    margin-top: 6px !important;
    padding-bottom: 0px;
}

.form-group label {
    min-width: 100%;
    min-height: 100%;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #646464;
    margin-left: 10px;
    padding-left: 0px;
}
.form-group-no-bottom div:nth-child(1) p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 150%;
    color: #646464;
}
.form-group-no-bottom div:nth-child(2) p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 150%;
    color: #646464;
    padding-bottom: 24px;
    margin-bottom: 0px;
}
form input[name='email'] {
    color: #191919;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
}
.controls .form-control {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 4px;
    background: white;
    border: 1px solid #C9CDDC;
    border-radius: 8px;
}

.has-error .form-control {
    border-color: #EB5757 !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

div#div_id_new_password1.form-group{
    margin-bottom: 12px;
}
div#div_id_new_password2.form-group{
    margin-bottom: 12px;
}
.form-group-bottom-12-px {
    margin-bottom: 12px;
}
.form-group-no-bottom {
    margin-bottom: 0px;
}
.form-group {
    margin-bottom: 0px;
}
.form-group-no-bottom button {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 12px 20px;
    gap: 10px;
    background: #F7F8FF;
    border-radius: 44px;
    float: right;
    color: #2022ED;
}
.form-group-no-bottom button:hover {
    background-color: #F7F8FF;
    color: #2022ED;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
}
.form-group-no-bottom button:focus {
    background-color: #F7F8FF;
    color: #2022ED;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
}
.form-group-no-bottom button:active {
    background-color: #F7F8FF;
    color: #2022ED;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
}

.form-group button {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 12px 20px;
    gap: 10px;
    background: #F7F8FF;
    border-radius: 44px;
    float: right;
    color: #2022ED;
}
.form-group button:hover {
    background-color: #F7F8FF;
    color: #2022ED;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
}
.form-group button:active {
    background-color: #F7F8FF;
    color: #2022ED;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
}
.form-group button:focus {
    background-color: #F7F8FF;
    color: #2022ED;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
}
.btn-pass button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    padding-top: 14px;
    gap: 24px;
    background-color: #FFFFFF;
    border: 0;
}
.btn-pass button a {
    padding: 12px 20px;
    gap: 10px;
    background: #F7F8FF;
    border-radius: 44px;
    font-family: "Roboto";
    font-style: normal;
    line-height: 150%;
    color: #2022ED;
    text-decoration: none;
}
.btn-pass .a button:hover {
    background-color: #FFFFFF;
    color: #2022ED;
}
a:focus {
    outline: 0px;
}
.return-btn button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    gap: 10px;
    background: #F5F5F5;
    border-radius: 44px;
    border: 0;
    margin-bottom: 8px;
}
.return-btn button a {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: #000000 !important;
}
div#div_id_email.form-group{
    margin-bottom: 24px;
}

.alert.alert-danger-login {
    background-color: #FFF7F9;
    color: #EB5757;
    border-color: #FFF7F9;
    background-image: url("img/logos/alert-triangle.svg");
    margin-bottom: 10px;
    background-position: left 15px top 17px;
}
strong {
    font-weight:normal;
}
.controls .help-block {
    font-family: "Roboto";
    font-style: normal;
    font-size: 12px;
    line-height: 150%;
    padding-top: 5px;
    margin-left: 10px;
    color: #EB5757;
}
.asteriskField {
    display: none;
}
.has-error .control-label {
    color: #EB5757;
}
